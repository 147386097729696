const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/gochat", "/gochat"],
    exact: true,
    component: "GoChat",
  },
  {
    path: ["/sleep", "/sleep"],
    exact: true,
    component: "Sleep",
  },
  {
    path: ["/meditation", "/meditation"],
    exact: true,
    component: "Meditation",
  }
];

export default routes;
